export default {
    computed: {
        headers() {
            return [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: 'translations[' + this.currentLocale + '].name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
            ]
        },
    },
}
